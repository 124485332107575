import { ACTIONS, DETAILED_ERROR_CODES, NAMESPACE_TALPA, NAMESPACE_TWC } from '../../constants';
import { createQueryString, getAppConfiguration, makeRequest, setLicenseRequestBody, getClientIP } from '../../utils';
// import getToken from '../../utils/getToken';
import getVideo from '../../utils/getVideo';
import { customInteraction, noticeError, setAdditionalAttributes } from '../../utils/newrelic';
import Logger from './Logger';

declare global {
  interface Window {
    isProduction: boolean;
    utag: {
      data: {
        tealium_visitor_id: string;
        tealium_profile: string;
        hashed_visitor_id?: string;
        app_brandname: string;
        app_name: string;
      };
    };
  }
}
export default class BasePlayer {
  contentId?: string;
  context: framework.CastReceiverContext;
  currentContentTime?: number;
  currentDuration: number;
  currentMediaTime?: number;
  currentTrack?: string;
  videoApiURL = '';
  logger: Logger;
  playerManager: framework.PlayerManager;
  textTracksManager: framework.TextTracksManager;
  request?: messages.LoadRequestData;
  textTracks: Track[] = [];
  uid?: string;
  videoElement: HTMLVideoElement;
  sendAdUpdates = false;
  streamStarted = false;
  drmToken: string | null = null;
  adPlaying = false;
  currentAd: {
    adId?: string;
    contentUrl?: string;
    duration?: number;
    sequence?: number;
    podcount?: number;
    vastMediaFiles?: string;
  } | null = null;
  creatives: {
    breakId: string;
    sequence: string;
    adId: string;
    creativeId: string;
    clickThroughUrl?: string;
    mediaFiles: string[];
    vastMediaFiles: string;
  }[] = [];
  loadRequest?: framework.messages.LoadRequestData;

  constructor() {
    this.context = cast.framework.CastReceiverContext.getInstance();
    this.logger = new Logger(this.context);
    this.playerManager = this.context.getPlayerManager();
    this.textTracksManager = this.playerManager.getTextTracksManager();
    this.currentDuration = this.playerManager.getDurationSec();
    this.videoElement = (document.getElementById('player') as any).getMediaElement();
    const options = new cast.framework.CastReceiverOptions();

    options.customNamespaces = {
      [NAMESPACE_TWC]: cast.framework.system.MessageType.STRING,
      [NAMESPACE_TALPA]: cast.framework.system.MessageType.STRING,
    };
    this.context.start(options);

    this.setupCallbacks();

    getClientIP().then((clientIP) => {
      setAdditionalAttributes({
        clientIP,
      });
    });
  }

  initMonitoring(_playerChannel: string, _videoMetadata: { [key: string]: any }) {}

  setupCallbacks() {
    // Receives messages from sender app. The message is a comma separated string
    // where the first substring indicates the function to be called and the
    // following substrings are the parameters to be passed to the function.
    this.context.addCustomMessageListener(NAMESPACE_TWC, (event) => {
      const message = event.data.split(',');
      const method = message[0];
      switch (method) {
        case 'requestAd':
          // Todo: check this action with app team
          // const adTag = message[1];
          // const currentTime = parseFloat(message[2]);
          // this.requestAd_(adTag, currentTime);
          this.broadcast('requestAd currently not implemented');
          break;
        case 'seek':
          const time = parseFloat(message[1]);
          this.seek_(time);
          break;
        default:
          this.broadcast('Message not recognized');
          break;
      }
    });

    this.context.addCustomMessageListener(NAMESPACE_TALPA, (event) => {
      const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
      const { type, value } = data;
      if (type === ACTIONS.SET_ACTIVE_TRACK) {
        this.currentTrack = value;
        this.setActiveCaption(value);
      }
    });

    this.playerManager.setMessageInterceptor(cast.framework.messages.MessageType.LOAD, async (loadRequestData) => {
      this.loadRequest = loadRequestData;
      const { contentId, contentUrl, customData } = loadRequestData.media;

      let env = 'prd';

      if (!window.isProduction) {
        const [, matchedEnv] = contentUrl?.match(/^https:\/\/vod(?:.*)\.(acc|tst)/) || [];
        env = matchedEnv || 'prd';
      }
      this.videoApiURL =
        customData?.videoApiEndpoint?.replace('/graphql', '') || `https://api.${env}.video.talpa.network`;

      // Live API workaround
      const breakManager = this.playerManager.getBreakManager();
      const interceptor = customData?.preventAds ? () => null : null;
      breakManager.setBreakClipLoadInterceptor(interceptor);

      setAdditionalAttributes({
        media_id: contentId,
        userAgent: navigator.userAgent,
        device_resolution: `${window.innerWidth}x${window.innerHeight}`,
        load_request_data: JSON.stringify(loadRequestData.media),
      });

      const video = await getVideo({ guid: contentId, videoApiURL: this.videoApiURL });
      this.drmToken = video?.drmToken || null;

      if (video) {
        if (video.source) {
          loadRequestData.media.contentUrl = video.source.file;
          setAdditionalAttributes({
            source_type: video?.source?.type,
            drm_type: video?.source?.drm && cast.framework.ContentProtection.WIDEVINE,
          });
        }
        loadRequestData.media.customData = {
          ...loadRequestData.media.customData,
          video,
        };
      }

      if (customData?.freewheelConfig) {
        const { globalParams, keyValues } = customData.freewheelConfig;

        const globalParameters = {
          ...globalParams,
          nw: '506166',
          prof: '506166:talpa_embedded3p_chromecast',
          resp: 'vmap1',
          caid: contentId,
          pvrn: Math.floor(Math.random() * 1e10), // random number
          vprn: Math.floor(Math.random() * 1e10), // random number
          flag: '+play+scpv+sltp+emcr+slcb+vicb+fbad+nucr+aeti',
          metr: '47',
        };

        const keyValueParameters = {
          ...keyValues,
          _fw_h_user_agent: navigator.userAgent,
        };

        loadRequestData.media.vmapAdsRequest = {
          adTagUrl: `https://7b936.v.fwmrm.net/ad/g/1?${createQueryString(globalParameters)};${createQueryString(
            keyValueParameters,
          )};`,
        };
      }
      return loadRequestData;
    });

    this.playerManager.setMediaPlaybackInfoHandler(async (loadRequest, playbackConfig) => {
      const { contentId, customData, vmapAdsRequest } = loadRequest.media;
      this.logger.setup(customData?.logging || {});

      this.logger.info('loadRequest', loadRequest);
      this.streamStarted = false;

      const { video, currentTrack, uid, sendAdUpdates } = (customData || {}) as {
        video?: { source: Source; tracks: Track[]; drmToken: string; metadata: Metadata };
        currentTrack?: string;
        uid?: string;
        sendAdUpdates?: boolean;
      };
      if (currentTrack) this.currentTrack = customData.currentTrack;
      if (sendAdUpdates) this.sendAdUpdates = sendAdUpdates;
      if (uid) this.uid = uid;

      this.contentId = contentId;
      this.textTracks = video?.tracks || [];

      // Setup DRM
      if (video?.source?.drm) {
        const {
          drm: { widevine },
        } = video.source;
        playbackConfig.protectionSystem = cast.framework.ContentProtection.WIDEVINE;
        playbackConfig.licenseUrl = widevine.url;
        playbackConfig.licenseRequestHandler = (requestInfo) => {
          requestInfo.headers = {
            Authorization: `Basic ${this.drmToken}`,
            'Content-Type': 'application/json',
          };
          requestInfo.content = setLicenseRequestBody(widevine.releasePid, requestInfo);
        };
      }

      const videoMetadata = video?.metadata || {};
      let playerChannel = customData?.playerChannel;
      if (!playerChannel) {
        // use ad request to determine player channel for app
        const appConfig = getAppConfiguration(vmapAdsRequest?.adTagUrl);
        if (appConfig) {
          playerChannel = appConfig.playerChannel;
          videoMetadata.app_platform = appConfig.platform;
        }
      }

      setAdditionalAttributes({
        player_channel: videoMetadata.app_platform ? `${playerChannel} ${videoMetadata.app_platform}` : playerChannel,
      });
      customInteraction('Player Ready');
      this.initMonitoring(playerChannel, videoMetadata);

      return playbackConfig;
    });

    this.playerManager.addEventListener(cast.framework.events.EventType.PLAYER_LOAD_COMPLETE, () => {
      this.currentDuration = this.playerManager.getDurationSec();
    });

    this.playerManager.addEventListener(cast.framework.events.EventType.CLIP_STARTED, (event) => {
      this.logger.debug(cast.framework.events.EventType.CLIP_STARTED, { adPlaying: this.adPlaying, event });
      if (this.adPlaying === false) {
        this.resetTracks();
        if (this.currentTrack) {
          this.setActiveCaption(this.currentTrack);
        }
      }
    });

    this.playerManager.addEventListener(cast.framework.events.EventType.BREAK_ENDED, () => {
      if (this.loadRequest?.media.duration === -1) {
        /**
         * The Live API currently does not support the Break API. That's why livestream complete after a break
         * https://developers.google.com/cast/docs/web_receiver/live
         * So we are reloading the loadRequest but remove all ad breaks from the configuration
         */
        const {
          media: { breakClips, breaks, ...media },
          requestId,
          type,
        } = this.loadRequest;
        this.logger.info('%c== Reload live media ==', 'font-weight: bold; background-color: black; color: white');
        customInteraction('Reload live media');
        this.playerManager.load({
          media: {
            ...media,
            customData: { preventAds: true },
          },
          requestId,
          type,
        });
      }
    });

    this.playerManager.setMessageInterceptor(cast.framework.messages.MessageType.EDIT_TRACKS_INFO, (request) => {
      this.logger.info('EDIT_TRACKS_INFO', request);
      const [activeTrackId] = request.activeTrackIds || [];

      const textTracksManager = this.playerManager.getTextTracksManager();
      const tracks = textTracksManager.getTracks();

      const target =
        request.enableTextTracks !== false && activeTrackId
          ? tracks.find((track) => track.trackId === activeTrackId)
          : { trackContentId: 'off', name: 'Off' };

      if (target) {
        this.currentTrack = target.trackContentId;
      }

      if (target) {
        this.sendCustomMessage({
          type: ACTIONS.TRACK_CHANGED,
          name: target.name,
          id: target.trackContentId,
        });
      }
      return request;
    });

    this.playerManager.addEventListener(cast.framework.events.EventType.TRACKS_CHANGED, (event) => {
      this.logger.info('TRACKS_CHANGED', event);
      const textTracksManager = this.playerManager.getTextTracksManager();
      const [track] = textTracksManager.getActiveTracks();
      this.sendCustomMessage({ type: ACTIONS.TRACK_CHANGED, value: track.name, source: 'eventListener' });
    });

    this.playerManager.addEventListener(cast.framework.events.EventType.ERROR, (event) => {
      const { error, detailedErrorCode, reason } = event;
      const customAttributes: { [key: string]: any } = {
        error_code: detailedErrorCode,
        error_group: detailedErrorCode && DETAILED_ERROR_CODES[detailedErrorCode],
        adPlaying: this.adPlaying || error?.message?.includes('Failed to request ads'),
      };

      if (error?.shakaErrorCode) {
        const { shakaErrorCode, shakaErrorData } = error;
        const [shakaError] = shakaErrorData || [];

        const [url, status, response] = shakaError?.data || [];

        Object.assign(customAttributes, {
          shaka_error_code: shakaErrorCode,
          shaka_url: url,
          shaka_status: status,
          shaka_response: response,
        });

        if (response === 'You must provide a valid token for authentication.') {
          customAttributes.drm_token = this.drmToken;
        }
      }

      this.logger.error(error, reason, customAttributes);
      noticeError(error?.message || '', customAttributes);
    });
  }

  resetTracks(): void {
    const textTracksManager = this.playerManager.getTextTracksManager();
    const tracks = textTracksManager.getTracks();
    if (!tracks.length && this.textTracks.length) {
      this.logger.info('reset missing tracks');

      const textTracks = this.textTracks.map((track) => {
        const newTextTrack = this.textTracksManager.createTrack();
        newTextTrack.language = 'nl';
        newTextTrack.name = track.label;
        newTextTrack.trackContentId = track.file;
        newTextTrack.trackContentType = 'text/vtt’';
        newTextTrack.type = cast.framework.messages.TrackType.TEXT;
        return newTextTrack;
      });
      this.logger.info('new tracks:', textTracks);
      textTracksManager.addTracks(textTracks);
    }
  }

  setActiveCaption(id: string): void {
    const textTracksManager = this.playerManager.getTextTracksManager();
    this.logger.info('setActiveCaption', id);
    if (id === 'off') {
      this.logger.info('Disable caption');
      this.sendCustomMessage({ type: ACTIONS.ACTIVE_TRACK_SET, id, name: 'Off' });
      return textTracksManager.setActiveByIds([]);
    }
    const tracks = textTracksManager.getTracks();
    const target = tracks.find((track) => track.trackContentId === id || track.name === id);
    if (target) {
      this.logger.info(`set track '${target.trackId}' to active`, target);
      this.sendCustomMessage({ type: ACTIONS.ACTIVE_TRACK_SET, id: target.trackContentId, name: target.name });

      textTracksManager.setActiveByIds([target.trackId]);
    }
  }

  heartbeat_(currentMediaTime: number): void {
    if (this.currentMediaTime && this.currentMediaTime === currentMediaTime) return;
    this.currentMediaTime = currentMediaTime;

    // Check if ad is currently being played
    // Heartbeat is not sent while ad is playing
    const breakClipTime = this.playerManager.getBreakClipCurrentTimeSec();
    if (breakClipTime != null) return;

    // Check if video progress is less than 5%
    // Heartbeat is sent only for video progress greater than 5%
    const videoProgressPercent = Math.round((currentMediaTime / this.currentDuration) * 100);
    if (videoProgressPercent < 5) return;

    if ((currentMediaTime <= 60 && currentMediaTime % 10 === 0) || currentMediaTime % 30 === 0) {
      const isComplete = videoProgressPercent >= 95;
      makeRequest(
        'GET',
        `${this.videoApiURL}/vpupdater?uid=${this.uid}&iid=${this.contentId}&prg=${currentMediaTime}&c=${isComplete}`,
      );
    }
  }

  /**
   * Sends messages to all connected sender apps.
   * @param {string} message Message to be sent to senders.
   * @private
   */
  broadcast(message: any) {
    this.context.sendCustomMessage(NAMESPACE_TWC, undefined, message);
  }

  sendCustomMessage(message: any) {
    this.context.sendCustomMessage(NAMESPACE_TALPA, undefined, JSON.stringify(message));
  }

  /**
   * Seeks content video.
   * @param {!float} time time to seek to.
   * @private
   */
  seek_(time: number) {
    this.currentContentTime = time;
    this.playerManager.seek(time);
    if (this.playerManager.getPlayerState() === cast.framework.messages.PlayerState.PAUSED) {
      this.playerManager.play();
    }
  }
}
