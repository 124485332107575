import Conviva from '@convivainc/conviva-js-coresdk';

import { ACTIONS, PUBLISHING_POINTS, TEALIUM_CALL_TYPES } from '../../constants';
import {
  clearAdAttributes,
  customInteraction,
  getCreatives,
  setAdditionalAttributes,
  setDataLayer,
  track,
} from '../../utils';
import { deleteNullValues } from '../../utils/object';
import Player from './BasePlayer';

export default class MonitoredPlayer extends Player {
  initialized = false;
  playerChannel: string = '';
  videoMetadata: { [key: string]: string | number | null } = {};
  eventLog: {
    eventLog: { event_action: string }[];
  } = { eventLog: [] };

  ad = {
    breakCount: 0,
    adposition: '',
    paused: false,
  };
  video = {
    seeking: false,
    volume: false,
    position: 0,
  };

  talpaPublishingPointPlayerChannels = ['kijk2 app', 'lindanlweb'];

  private videoAnalytics: Conviva.VideoAnalytics;
  private adAnalytics: Conviva.AdAnalytics;

  heartbeatTimeout: NodeJS.Timer | null = null;

  constructor() {
    super();

    const convivaApiKey = process.env.CONVIVA_API_KEY;

    const debugCookiePresent = document.cookie.indexOf('__CONVIVA_DEBUG__') >= 0;

    const settings = {
      [Conviva.Constants.LOG_LEVEL]: debugCookiePresent
        ? Conviva.Constants.LogLevel.DEBUG
        : Conviva.Constants.LogLevel.NONE,
    };

    Conviva.Analytics.init(convivaApiKey!, null, settings);

    const deviceMetadata = {
      [Conviva.Constants.DeviceMetadata.CATEGORY]: Conviva.Constants.DeviceCategory.WEB,
    };

    Conviva.Analytics.setDeviceMetadata(deviceMetadata);

    this.videoAnalytics = Conviva.Analytics.buildVideoAnalytics();
    this.adAnalytics = Conviva.Analytics.buildAdAnalytics();
  }

  castToStringRecord = (metadata: Record<string, string | number | null>): Record<string, string> => {
    return Object.fromEntries(
      Object.entries(metadata).map(([key, value]) => [key, value === null ? '' : String(value)]),
    );
  };

  getMetadata = (): Conviva.ConvivaMetadata => {
    const { media_duration, media_id, player_name, player_version, player_channel } = this.getTrackData() as {
      media_duration: number;
      media_id: string;
      player_name: string;
      player_version: string;
      player_channel: string;
    };

    const isLive = media_duration === 0;

    const hashedVisitorId = window.utag.data.hashed_visitor_id || '';

    const c_sko_pub = this.talpaPublishingPointPlayerChannels.includes(this.playerChannel)
      ? PUBLISHING_POINTS.TALPA
      : PUBLISHING_POINTS.DEFAULT;

    return {
      [Conviva.Constants.DURATION]: isLive ? -1 : (media_duration as any), // Band-aid fix for incorrect type from Conviva SDK
      [Conviva.Constants.IS_LIVE]: isLive ? Conviva.Constants.StreamType.LIVE : Conviva.Constants.StreamType.VOD,
      [Conviva.Constants.ASSET_NAME]: media_id,
      [Conviva.Constants.STREAM_URL]: this.loadRequest?.media.contentUrl || '',
      [Conviva.Constants.PLAYER_NAME]: player_name,
      [Conviva.Constants.FRAMEWORK_NAME]: 'caf',
      [Conviva.Constants.FRAMEWORK_VERSION]: player_version,
      [Conviva.Constants.DEFAULT_RESOURCE]: 'CLOUDFRONT',
      [Conviva.Constants.VIEWER_ID]: hashedVisitorId,
      media_type: 'video',
      page_url: document.location.href,
      parent_hostname: document.location.hostname,
      player_type: 'caf',
      player_channel,
      tealium_profile_name: window.utag.data.tealium_profile || '',
      app_brandname: window.utag.data.app_brandname || '',
      app_name: window.utag.data.app_name || '',
      hashed_visitor_id: hashedVisitorId,
      c_sko_pub,
      ...this.castToStringRecord(this.videoMetadata),
    };
  };

  initMonitoring(playerChannel: string, videoMetadata: { [key: string]: any }) {
    this.eventLog = (window as any).Taq.getExtension('eventLog');
    if (!this.eventLog) {
      this.logger.warn('EventLog must be loaded');
      return;
    }

    if (!playerChannel) {
      this.logger.warn('Provide playerChannel and videoMetadata to start video monitoring');
      return;
    }
    this.playerChannel = playerChannel;
    this.videoMetadata = videoMetadata;
    if (this.initialized) {
      this.logger.debug('Monitoring already initialized');
      return;
    }
    this.subscribeToEvents();
  }

  cleanup() {
    this.videoAnalytics.release();
    this.adAnalytics.release();
    Conviva.Analytics.release();
  }

  isLastEvent = (action: string): boolean => {
    let lastEvent = this.eventLog.eventLog[this.eventLog.eventLog.length - 1];
    if (!lastEvent) {
      return false;
    }

    return lastEvent.event_action === action;
  };

  subscribeToEvents = () => {
    this.initialized = true;
    const events: [event: framework.events.EventType, callback: (event: any) => any][] = [
      [cast.framework.events.EventType.BREAK_STARTED, this.onBreakStarted],
      [cast.framework.events.EventType.BREAK_ENDED, this.onBreakEnded],
      [cast.framework.events.EventType.BREAK_CLIP_LOADING, this.onBreakClipLoading],
      [cast.framework.events.EventType.BREAK_CLIP_ENDED, this.onBreakClipEnded],
      [cast.framework.events.EventType.PLAY, this.onPlay],
      [cast.framework.events.EventType.PAUSE, this.onPause],
      [cast.framework.events.EventType.TIME_UPDATE, this.onTimeUpdate],
      [cast.framework.events.EventType.REQUEST_SEEK, this.onRequestSeek],
      [cast.framework.events.EventType.SEEKING, this.onSeeking],
      [cast.framework.events.EventType.SEEKED, this.onSeeked],
      [cast.framework.events.EventType.MEDIA_FINISHED, this.onMediaFinished],
      [cast.framework.events.EventType.PLAYER_LOAD_COMPLETE, this.onPlayerLoadComplete],
      [cast.framework.events.EventType.BITRATE_CHANGED, this.onBitrateChanged],
      [cast.framework.events.EventType.BUFFERING, this.onBuffering],
      [cast.framework.events.EventType.ERROR, this.onError],
    ];

    events.forEach(([eventType, callback]) => {
      this.logger.debug('[subscribeToEvents] listen to ', eventType);
      this.playerManager.addEventListener(eventType, this.eventWrapper(callback));
    });

    window.addEventListener('beforeunload', this.cleanup);
  };

  startHeartbeat = () => {
    if (this.heartbeatTimeout) {
      return;
    }

    this.heartbeatTimeout = setInterval(() => {
      if (this.adPlaying) {
        this.adAnalytics.reportAdMetric(Conviva.Constants.Playback.PLAY_HEAD_TIME, this.currentContentTime);
        return;
      }

      this.videoAnalytics.reportPlaybackMetric(Conviva.Constants.Playback.PLAY_HEAD_TIME, this.currentContentTime);
    }, 1000);
  };

  stopHeartbeat = () => {
    if (!this.heartbeatTimeout) {
      return;
    }

    clearInterval(this.heartbeatTimeout);
    this.heartbeatTimeout = null;
  };

  reportPlaybackMetric = (
    metricKey: keyof Conviva.ConvivaConstants['Playback'],
    metricValue?: keyof Conviva.ConvivaConstants['PlayerState'] | number | string,
  ) => {
    this.videoAnalytics.reportPlaybackMetric(metricKey, metricValue);

    if (metricKey === Conviva.Constants.Playback.PLAYER_STATE) {
      if (
        metricValue === Conviva.Constants.PlayerState.PLAYING ||
        metricValue === Conviva.Constants.PlayerState.BUFFERING
      ) {
        return this.startHeartbeat();
      }

      this.stopHeartbeat();
    }
  };

  reportAdMetric = (
    metricKey: keyof Conviva.ConvivaConstants['Playback'],
    metricValue: keyof Conviva.ConvivaConstants['PlayerState'] | number | string,
  ) => {
    this.adAnalytics.reportAdMetric(metricKey, metricValue);

    if (metricKey === Conviva.Constants.Playback.PLAYER_STATE) {
      if (
        metricValue === Conviva.Constants.PlayerState.PLAYING ||
        metricValue === Conviva.Constants.PlayerState.BUFFERING
      ) {
        return this.startHeartbeat();
      }

      this.stopHeartbeat();
    }
  };

  eventWrapper = (callback: any) => (event: any) => {
    const collectCallbackData = callback.call(this, event);

    // When we return false in a callback we will not fire the track event any more
    if (collectCallbackData === false) {
      return;
    }
    const trackData = this.getTrackData.call(this);

    setDataLayer({ ...trackData, ...collectCallbackData });
    track(TEALIUM_CALL_TYPES.VIDEO);
  };

  formatAdEvent = (
    data: { [key: string]: string },
    currentAd?: Player['currentAd'],
  ): Record<string, string | number | undefined> | false => {
    const ad = this.currentAd || currentAd;
    if (!ad) return false;

    return {
      ...data,
      event_category: 'player',
      ad: 1,
      ad_id: ad.adId,
      ad_position: this.ad.adposition + '-roll',
      ad_client: 'castsdk',
      ad_podcount: ad.podcount,
      ad_sequence: ad.sequence,
      ad_breakcount: this.ad.breakCount,
      ad_duration: ad.duration || 0,
    };
  };

  getTrackData = (): { [key: string]: string | boolean | null | number } => {
    let stats: framework.Stats | null = null;
    let playerState = '';
    try {
      stats = this.playerManager.getStats();
      playerState = this.playerManager.getPlayerState().toLowerCase();
    } catch (ignore) {}

    const trackData = {
      ad: 0,
      c_player_position: this.video.position,
      media_bitrate: stats?.streamBandwidth ? `${stats.streamBandwidth / 1000}kbps` : null,
      media_offset: this.video.position * 1000,
      media_playbackpercentage: 100,
      media_playbackrate: 1,
      media_quality: stats?.height ? `${stats.height}p` : 'auto',
      media_type: 'video',
      player_casting: true,
      player_state: playerState,
      player_height: window.innerHeight,
      player_width: window.innerWidth,
      player_channel: this.playerChannel,
      player_channelid: this.playerChannel,
      player_id: 'caf_receiver',
      player_name: 'caf',
      ...this.videoMetadata,
    };

    return trackData;
  };

  // Event callbacks

  onPlayerLoadComplete = () => {
    this.video.position = 0;

    const mediaInformation = this.playerManager.getMediaInformation();
    this.videoMetadata = {
      ...this.videoMetadata,
      media_drmtype: mediaInformation?.customData?.drm?.type || 'none',
      media_encodingformat: mediaInformation?.contentType || null,
      media_thumbnail: (mediaInformation?.metadata as framework.messages.GenericMediaMetadata)?.images?.[0].url || null,
      player_source: mediaInformation?.contentUrl || null,
    };

    const metadata = this.getMetadata();
    this.videoAnalytics.reportPlaybackRequested(metadata);
    this.videoAnalytics.setPlayer(this.playerManager);

    return {
      event_category: 'player',
      event_label: 'loadclip',
      event_action: 'loadclip',
      event_name: 'player-loadclip',
    };
  };

  onBreakStarted(event: framework.events.BreaksEvent) {
    const breakManager = this.playerManager.getBreakManager();
    const breaks = breakManager.getBreaks();

    const currentBreak = breaks.find(({ id }) => id === event.breakId);

    let adPosition = 'mid';
    if (currentBreak?.position === 0) adPosition = 'pre';
    if (currentBreak?.position === -1) adPosition = 'post';

    this.adPlaying = true;
    this.ad.adposition = adPosition;
    this.ad.breakCount++;

    this.videoAnalytics.reportAdBreakStarted(Conviva.Constants.AdType.CLIENT_SIDE, Conviva.Constants.AdPlayer.CONTENT);

    return false;
  }

  onBreakEnded(_event: framework.events.BreaksEvent) {
    this.adPlaying = false;
    if (this.sendAdUpdates) {
      const breakManager = this.playerManager.getBreakManager();
      const breaks = breakManager.getBreaks();
      this.sendCustomMessage({
        type: ACTIONS.ON_BREAK_CHANGED,
        value: false,
        breaks,
      });
    }

    this.videoAnalytics.reportAdBreakEnded();

    return false;
  }

  onBreakClipLoading = (event: framework.events.BreaksEvent) => {
    this.logger.debug(cast.framework.events.EventType.BREAK_CLIP_LOADING, event);
    this.currentAd = null;
    this.ad.paused = false;
    const breakManager = this.playerManager.getBreakManager();

    const clips = breakManager.getBreakClips();
    this.creatives = getCreatives(clips);
    console.log({
      clips,
      creatives: this.creatives,
    });
    const activeClip = clips.find((clip) => clip.id === event.breakClipId);

    if (activeClip) {
      const creative = this.creatives.find(
        ({ clickThroughUrl, mediaFiles }) =>
          (activeClip.clickThroughUrl && activeClip.clickThroughUrl === clickThroughUrl) ||
          (activeClip.contentId && mediaFiles.includes(activeClip.contentId)),
      );
      setAdditionalAttributes({
        ad_id: creative?.adId || activeClip.contentId,
        creative_id: creative?.creativeId,
        ad_content_url: activeClip.contentId,
        vast_media_files: creative?.vastMediaFiles,
      });
      this.currentAd = {
        ...creative,
        adId: creative?.adId || activeClip.contentId,
        contentUrl: activeClip.contentId,
        duration: activeClip.duration,
        sequence: event.index,
        podcount: event.total,
      };

      if (this.sendAdUpdates) {
        const breaks = breakManager.getBreaks();
        this.sendCustomMessage({
          type: ACTIONS.ON_BREAK_CHANGED,
          value: true,
          duration: activeClip.duration,
          sequence: event.index,
          podcount: event.total,
          breaks,
        });
      }
    }
    return false;
  };

  onBreakClipEnded = (event: framework.events.BreaksEvent) => {
    this.logger.debug(cast.framework.events.EventType.BREAK_CLIP_ENDED, event);

    const nrAdSuffix = this.currentAd
      ? ` ${this.ad.adposition}-roll | ${this.currentAd.sequence} of ${this.currentAd.podcount}`
      : '';

    if (event.endedReason === 'END_OF_STREAM') {
      //call your ad tracking code here for break clip watched to completion
      customInteraction('Ad watched' + nrAdSuffix);
      const currentAd = { ...this.currentAd };
      this.currentAd = null;
      clearAdAttributes();
      return this.onAdComplete(currentAd);
    }

    if (event.endedReason === 'ERROR') {
      this.adAnalytics.reportAdError(`Failed Ad ${nrAdSuffix}`, 1);

      customInteraction('Failed Ad' + nrAdSuffix);
    }

    this.currentAd = null;
    clearAdAttributes();

    this.adAnalytics.reportAdEnded();

    return false;
  };

  onAdPlay() {
    const action = this.ad.paused ? 'resume' : 'start';
    this.ad.paused = false;

    const adEvent = this.formatAdEvent({
      event_action: `ad_${action}`,
      event_label: `ad_${action}`,
      event_name: `player-ad_${action}`,
    });

    if (adEvent) {
      const customTags = { ...adEvent };

      Object.keys(customTags).forEach((key) => {
        if (key.startsWith('event_') || key === 'ad') {
          delete customTags[key];
        }
      });

      const metadata = {
        ...this.getMetadata(),
        [Conviva.Constants.DURATION]: adEvent.ad_duration,
        [Conviva.Constants.IS_LIVE]: Conviva.Constants.StreamType.VOD,
        [Conviva.Constants.ASSET_NAME]: adEvent.ad_id,
        [Conviva.Constants.STREAM_URL]: this.currentAd ? this.currentAd.contentUrl : '',
        [Conviva.Constants.DEFAULT_RESOURCE]: null,
        ...customTags,
      };

      deleteNullValues(metadata);

      this.adAnalytics.reportAdStarted(metadata as Conviva.ConvivaMetadata);
    }

    return adEvent;
  }

  onAdPause() {
    this.ad.paused = true;

    this.reportAdMetric(Conviva.Constants.Playback.PLAYER_STATE, Conviva.Constants.PlayerState.PAUSED);

    return this.formatAdEvent({
      event_action: 'ad_pause',
      event_label: 'ad_pause',
      event_name: 'player-ad_pause',
    });
  }

  onAdComplete = (currentAd: Player['currentAd']) => {
    this.adAnalytics.reportAdEnded();

    return this.formatAdEvent(
      {
        event_action: 'ad_complete',
        event_label: 'ad_complete',
        event_name: 'player-ad_complete',
      },
      currentAd,
    );
  };

  onPlay(event: framework.events.MediaElementEvent) {
    if (this.adPlaying) {
      return this.onAdPlay();
    }

    if (this.isLastEvent('play') || this.isLastEvent('resume')) {
      return false;
    }

    this.reportPlaybackMetric(Conviva.Constants.Playback.PLAYER_STATE, Conviva.Constants.PlayerState.PLAYING);

    let eventAction = 'play';
    if (this.streamStarted === false) {
      this.streamStarted = true;
      customInteraction('Stream start');
      eventAction = 'start';
    } else if (this.isLastEvent('pause')) {
      eventAction = 'resume';
    }

    return {
      event_category: 'player',
      event_action: eventAction,
      event_label: eventAction,
      event_name: `player-${eventAction}`,
    };
  }

  onPause(event: framework.events.MediaPauseEvent) {
    if (event.ended) return false;

    if (this.adPlaying) {
      return this.onAdPause();
    }

    this.ad.paused = true;

    if (this.isLastEvent('pause')) {
      return false;
    }

    this.reportPlaybackMetric(Conviva.Constants.Playback.PLAYER_STATE, Conviva.Constants.PlayerState.PAUSED);

    return {
      event_category: 'player',
      event_action: 'pause',
      event_label: 'pause',
      event_name: 'player-pause',
    };
  }

  onTimeUpdate(event: framework.events.MediaElementEvent) {
    if (!event.currentMediaTime) return false;

    if (!this.adPlaying) {
      this.video.position = event.currentMediaTime;
      const utag = (window as any).utag;
      if (utag && utag.media) {
        utag.media.video_current_playhead = event.currentMediaTime;
      }

      if (this.uid && this.contentId) {
        this.heartbeat_(Math.round(event.currentMediaTime));
      }
    }

    if (this.sendAdUpdates && this.adPlaying) {
      this.sendCustomMessage({
        type: ACTIONS.BREAK_TIME_UPDATE,
        value: event.currentMediaTime,
        duration: this.currentAd?.duration,
      });
    }

    return false;
  }

  onRequestSeek(event: framework.events.RequestEvent) {
    if (this.adPlaying || !event.requestData) return false;
    this.video.seeking = true;

    return false;
  }

  onSeeking(event: framework.events.MediaElementEvent) {
    this.reportPlaybackMetric(Conviva.Constants.Playback.SEEK_STARTED, event.currentMediaTime);
  }

  onSeeked(event: framework.events.MediaElementEvent) {
    if (this.adPlaying || !event.currentMediaTime || !this.video.seeking) return false;

    this.video.seeking = false;

    this.reportPlaybackMetric(Conviva.Constants.Playback.SEEK_ENDED);

    if (this.playerManager.getPlayerState() === (Conviva.Constants.PlayerState.BUFFERING as string)) {
      this.reportPlaybackMetric(Conviva.Constants.Playback.PLAYER_STATE, Conviva.Constants.PlayerState.BUFFERING);
    }

    return {
      event_category: 'player',
      event_action: 'seeked',
      event_name: 'player-seeked',
      event_label: event.currentMediaTime,
      media_offset: event.currentMediaTime,
    };
  }

  onMediaFinished(_event: framework.events.MediaFinishedEvent) {
    customInteraction('Media finished');

    this.videoAnalytics.reportPlaybackEnded();
    this.stopHeartbeat();
    this.cleanup();

    return {
      event_category: 'player',
      event_action: 'complete',
      event_name: 'player-complete',
    };
  }

  onBitrateChanged(event: framework.events.BitrateChangedEvent) {
    this.reportPlaybackMetric(Conviva.Constants.Playback.BITRATE, event.totalBitrate);
  }

  onBuffering(event: framework.events.BufferingEvent) {
    this.reportPlaybackMetric(
      Conviva.Constants.Playback.PLAYER_STATE,
      event.isBuffering ? Conviva.Constants.PlayerState.BUFFERING : Conviva.Constants.PlayerState.PLAYING,
    );
  }

  onError(event: framework.events.ErrorEvent) {
    if (event.reason) {
      this.videoAnalytics.reportPlaybackError(event.reason.toString(), 1);
    }
  }
}
